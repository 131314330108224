.overflow-content {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.ms-TextField-suffix:empty {
  display: none;
}

.icon-language {
  margin-left: 5px;
  margin-top: -5px;
}

.vertical-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cell-ellipsis > div {
  display: block !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.default-column > div * {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}