.content-header {

  &.mobile {
    padding-top: calc(var(--header-height) + 15px);
  }

  &__content {
    margin: 0 0 13px 0;
  }

  &__title {
    color: var(--neutral-primary);
    font-weight: 700;
    font-size: 22px;

    &.mobile {
      margin-left: 0px;
    }
  }

  &__custom-group-header {
    position: relative;
    display: flex;
    align-items: center;
  }

  &__custom-group-header:hover {
    background-color: var(--ultra-black);
  }

  &__title-tag {
    position: relative;
    top: 1px;
    margin-left: 10px;
    color: var(--white);
    background-color: var(--neutral-primary);
    padding: 1px 4px 1px 4px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 700;
  }

  &__title-tag-navLink {
    color: var(--white);
    background-color: var(--neutral-primary);
    padding: 1px 4px 1px 4px;
    border-radius: 4px;
    font-size: 10px;
    font-weight: 700;
    position: absolute;
    right: 30px;
    margin-top: 3px;
  }

  &__persona {
    margin-left: auto;

    &__button {
      background-color: transparent;
      border: none;
    }
  }

  &__separator {
    &--horizontal {
      padding: 3px;

      &::before {
        background-color: var(--theme-tertiary);
      }

    }
  }
}
