.mt-1 {
  margin-top: 10px;
}

.mt-2 {
  margin-top: 20px;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mb-2 {
  margin-bottom: 20px;
}

.mr-1 {
  margin-right: 10px;
}

.mr-2 {
  margin-right: 20px;
}

.ml-2 {
  margin-left: 20px;
}

.ml-0 {
  margin-left: 0px;
}

.mr-0 {
  margin-right: 0px;
}

.pt-0 {
  padding-top: 0;
}

.pt-1 {
  padding-top: 12px
}

.pb-1 {
  padding-bottom: 12px
}

.pr-2 {
  padding-right: 22px;
}

.pl-2 {
  padding-left: 22px;
}

.mb-sm-2 {
  margin-bottom: 22px
}

.min-h-400 {
    min-height: 400px;
}
@media (max-width: 800px) {
  .mb-sm-2 {
    margin-bottom: 22px,
  }
}