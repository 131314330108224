.login {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &__wrapper {
    position: relative;
  }

  &__card {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
    width: 436px;
    height: 526px;
    background-color: rgba(0,0,0,0.5);
    border-bottom: 4px solid #E56727;

    &__logo {
      margin-bottom: 26px;
    }

    &__title {
      width: 314px;
      color: white;
      text-align: center;
      font-size: 24px;
      font-weight: 300;
      margin-bottom: 42px;
    }

    &__divider {
      width: 95px;
      height: 5px;
      border: none;
      background-color: #E56727;
    }

    &__auth {
      display:flex;
      flex-direction: column;
      align-items: center;

      .login__error {
        width: 314px;
        color: white;
        text-align: center;
        font-size: 24px;
        font-weight: 300;
      }

      .microsoft-oauth-button {
        margin-bottom: 25px;
      }

      .microsoft-oauth-button,
      .google-oauth-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 215px;
        height: 36px;
        border: 1px solid #8c8c8c !important;
        background: white;

        img {
          margin-right: 20px !important;
          border-radius: 0 !important;
        }

        span {
          font-weight: 600 !important;
          color: #5e5e5e;
        }
      }

      .microsoft-oauth-button:hover,
      .google-oauth-btn:hover {
        cursor: pointer;
        background: #dedcdc;
        color: #5a5f64;
      }
    }
  }

  &__card-shadow {
    position: absolute;
    bottom: -25px;
    width: 438px;
    height: 43px;
    border-radius: 50%;
    background-color: rgba(0,0,0,0.7);
    -webkit-filter: blur(35px);
    -moz-filter: blur(35px);
    -o-filter: blur(35px);
    -ms-filter: blur(35px);
    filter: blur(35px);
  }

  &__footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    bottom: 50px;
  }
}


@media(max-width: 480px) {
  .login {
    overflow: hidden;
    &__wrapper {
      height: 100vh;
    }
    &__card {
      width: 100vw;
      background-color: transparent;
      border-bottom: 0px solid black;
    }

    .login__footer__logo {
      display: none;
    }
  }
}
