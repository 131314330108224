.header-bar-persona_user_context_menu {
  width: 250px;
  position: fixed;
  padding: 10px 0 10px 0;
  top: 53px;
  right: 10px;
  background-color: var(--neutral-primary);
  color: var(--white);
  z-index: 1;
  border-radius: 0 0 10px 10px;
  max-width: 270px;

  .header-bar-persona_user_context_menu__top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    * {
      display: inline-block;
    }
  }

  #header-bar-persona_user_context_menu-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: inherit;
    border: none;
    font-family: Open Sans, serif;
    font-size: 16px;
    float: right;
    cursor: pointer;
    margin: 5px;
    .header-bar-persona_user_context_menu-icon {
      margin-right: 6px;
    }
  }

  .header-bar-persona_user_context_menu__user__info {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    font-size: 14px;
    margin-bottom: 10px;
    border-bottom: 1px solid var(--neutral-primary-alt);
    border-top: 1px solid var(--neutral-primary-alt);

    .header-bar-persona_user_context_menu__icons {
      font-size: 17px;
      margin: 15px 10px;
      white-space: nowrap;

      img {
        height: 17px;
        top: 0;
      }
    }

    span {
      display: block;
    }
  }

  .header-bar-persona_user_context_menu__user__info__name {
    font-weight: bold;
  }

  .header-bar-persona_user_context_menu__user__info__email {
    white-space: nowrap;
    overflow: hidden;
    width: 180px;
    text-overflow: ellipsis;
  }
}

.header-bar-mobile {
  right: 0;
  width: 100%;
  top: var(--header-height);

  .header-bar-persona_user_context_menu__user__info {
    margin-top: 0;
    border-top: none;
  }
}
