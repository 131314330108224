@import '@flexxibleit/flexxible-ui/styles/media_queries';

.view-as-end-user__wrapper {
  position: absolute;
  box-sizing: border-box;
  height: 90px;
  width: 100%;
  padding: 10px 10px;
  z-index: 10;
  background-color: var(--black);

  display: flex;
  justify-content: center;
}

#root .sidebar {
  bottom: 0;
  height: calc(100vh - var(--header-height));
  width: 240px;
  position: fixed;
  background: var(--black);
  z-index: 2;

  .sidebar-header {
    display: flex;
    position: fixed;
    justify-content: center;
    align-items: center;
    width: 240px;
    background-color: var(--black);
    height: var(--header-height);
    cursor: pointer;

    &__logo {
      padding-left: 0;
      width: 161px;
      display: inline-block;
    }
  }

  @include is-mobile-and-tablet {
    position: absolute;
    transform: translateX(-100vh);
    transition: all 0.3s ease-in-out;
    height: calc(100vh - var(--header-height));
  }

  &.open {
    transform: translateX(0px);
  }

  .ms-Nav {
    width: 240px;
    bottom: 0;
    height: calc(100vh - var(--header-height));
    box-sizing: border-box;
    background: var(--black);
    position: fixed;
    scrollbar-color: var(--neutral-primary-alt) var(--black);
    scrollbar-width: thin;

    overflow-y: auto;

    &.side-navbar {
      height: calc(100vh - 200px);
      top: var(--header-height);
    }

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      height: 10px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 5rem;
      background-color: #122730;
    }

    &.mobile {
      bottom: 0;
      height: calc(100vh - var(--header-height));
    }

    .ms-Nav-compositeLink {
      .ms-Button {
        &-icon {
          margin: 10px 15px;
          color: var(--white);
        }
      }

      &.is-selected {
        a span div {
          color: var(--theme-primary);
        }

        .ms-Button {
          background-color: var(--ultra-black);
        }
      }
      .content-header__title-tag-navLink:hover {
        background-color: var(--neutral-primary);
      }
      .content-header__title-tag-navLink {
        pointer-events: none;
      }

      &:hover {
        background-color: var(--ultra-black);
        .ms-Nav-link {
          background-color: var(--ultra-black);
        }
        .ms-Nav-linkText {
          color: var(--theme-primary);
        }
      }

      .ms-Nav-linkText {
        font-weight: 400;
        font-size: 15px;
        color: var(--white);
      }
    }

    .ms-Nav-group:nth-child(n + 2) .ms-Nav-navItems {
      background-color: var(--ultra-black);
    }

    .ms-Nav-group {
      .ms-Nav-chevronButton {
        border-bottom: none;
        margin: 0;
        font-weight: 400;
        font-size: 15px;
        padding-left: 54px;

        &,
        .ms-Nav-chevron {
          color: var(--white);
        }

        .ms-Nav-chevron {
          right: 10px;
          left: inherit;
          color: var(--theme-primary);
        }
      }
      .custom-icon {
        left: 0;
        position: absolute;
        color: #fff;
        margin: 15px 19px;
        font-size: 16px;
      }
      .ms-Icon {
        font-size: 16px;
      }
    }

    .ms-Nav-groupContent {
      margin-bottom: 0;
    }
  }
}
