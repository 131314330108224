.header-bar {
  width: 100%;
  height: var(--header-height);
  background-color: var(--black);
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: space-between;
  top: 0;
  z-index: 3;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-color: var(--neutral-primary-alt) var(--black);
  scrollbar-width: thin;

  &__logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  &__logo {
    width: 161px;
    height: 100%;
    display: inline-block;
    margin: 0 10px;
  }

  &__icons {
    color: white;
    font-size: 24px;
  }

  &__actions-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin-right: 10px;
    gap: 10px;
  }

  &__persona {
    width: 250px;

    &__button {
      background-color: transparent;
      border: none;
    }

    .header-bar__user__info {
      background-color: var(--neutral-primary);
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      border: var(--neutral-primary) 1px solid;
      border-radius: 10px;
      color: var(--white);
      font-size: 14px;
      line-height: 20px;
      padding: 4px 15px;

      span {
        display: block;
      }

      &:hover {
        cursor: pointer;
        border-color: var(--neutral-primary-alt);
      }
    }

    .header-bar__user__info__container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }

    .header-bar__user__info__name {
      color: var(--white);
      font-weight: bold;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 180px;
    }

    .header-bar__user__info__email {
      color: var(--white);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 150px;
    }

    .header-bar__user__info__settings {
      border: none;
      background-color: inherit;
      color: var(--neutral-primary);
      font-size: 16px;
    }
  }

  &__menu {
    margin: 0;
    border: none;
    padding: 25px 17px;
    background-color: transparent;
  }

  button {
    cursor: pointer;
  }

  &__organization_selector_container {
    color: var(--black);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-left: 5px;

    .ms-Dropdown:hover .ms-Dropdown-title {
      border-color: var(--neutral-primary-alt);
    }
    .ms-Dropdown:hover,
    .ms-Dropdown:focus {
      .ms-Dropdown-caretDown {
        color: var(--neutral-light);
      }
    }
  }
}

.organization_type_img {
  margin: 0 8px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
