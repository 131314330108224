.badge-component {
  background-color: rgb(255,101,0);
  border-radius: 4px;
  color: white;
  display: inline-block;
  font-size: 11px;
  font-weight: 700;
  margin-right: 5px;
  padding: 0px 4px;
  line-height: 16px;
}