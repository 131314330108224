.view-as-end-user {
  width: fit-content;

  &__label {
    color: white;
    margin-left: -10px;
  }

  .ms-Toggle-background {
    &:hover {
      background-color: #1e2022;
    }
  }
}
