body {
  background-color: var(--neutral-lighter-alt);
}
.App {
  width: calc(100% - 240px);
  float: right;
  height: 100vh;
  overflow-x: hidden;
}

@media (max-width: 900px) {
  .App {
    width: 100%;
  }
}

.App-logo {
  height: 10vmin;
  pointer-events: none;
}

.App-header {
  background-color: var(--black);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.selection-area {
  background: rgba(255, 166, 108, 0.11);
  border: 2px solid rgba(255, 120, 31, 0.81);
  border-radius: 0.1em;
}

@keyframes dots {
  0% {
    content: '';
  }
  33% {
    content: '.';
  }
  66% {
    content: '..';
  }
  100% {
    content: '...';
  }
}

.dots::after {
  content: '';
  animation: dots 2s steps(3, end) infinite;
}
