.sidebar_organization_selector__header {
  color: var(--white);
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.sidebar_organization_selector__label {
  font-size: 14px;
  font-weight: bold;
  margin-right: 10px;
}
