.banner {
  &__wrapper {
    background-color: black;
    z-index: 990;
    bottom: 0;
    position: fixed;
    height: var(--banner-height);
    display: flex;
    align-items: center;
    padding: 0 20px;
    width: 100%;
    gap: 10px;
    justify-content: center;
  }
}
