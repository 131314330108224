.app-switcher {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--neutral-dark);
  border-radius: 5px;
  font-size: 40px;
  color: var(--white);
  cursor: pointer;
  user-select: none;

  &:hover {
    background-color: var(--ultra-black);
  }

  &__open {
    background-color: var(--ultra-black);
  }

  &.mobile {
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    background-color: transparent;
    width: 50px;
    height: 50px;

    .app-selector {
      padding: 10px;
      top: 70px;
      left: 0;
      width: 100%;

      -webkit-animation: scale-up-top 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
      animation: scale-up-top 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;

      @-webkit-keyframes scale-up-top {
        0% {
          -webkit-transform: scale(0.5);
          transform: scale(0.5);
          -webkit-transform-origin: 50% 0;
          transform-origin: 50% 0;
        }
        100% {
          -webkit-transform: scale(1);
          transform: scale(1);
          -webkit-transform-origin: 50% 0;
          transform-origin: 50% 0;
        }
      }
      @keyframes scale-up-top {
        0% {
          -webkit-transform: scale(0.5);
          transform: scale(0.5);
          -webkit-transform-origin: 50% 0;
          transform-origin: 50% 0;
        }
        100% {
          -webkit-transform: scale(1);
          transform: scale(1);
          -webkit-transform-origin: 50% 0;
          transform-origin: 50% 0;
        }
      }
    }
  }

  .app-selector {
    background-color: var(--black);
    position: fixed;
    z-index: 3;
    top: -30px;
    left: 0;
    width: 240px;
    height: 100%;
    border-radius: 0 5px 5px 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 0.5s ease-in-out;

    -webkit-animation: slide-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

    @-webkit-keyframes slide-bottom {
      0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
      }
      100% {
        -webkit-transform: translateY(100px);
        transform: translateY(100px);
      }
    }
    @keyframes slide-bottom {
      0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
      }
      100% {
        -webkit-transform: translateY(100px);
        transform: translateY(100px);
      }
    }

    &__apps {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      width: 100%;

      &__app {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
        text-align: left;
        cursor: pointer;
        width: 100%;
        height: 44px;

        &-icon {
          margin: 10px 15px;
          color: var(--white);
          max-width: 20px;
        }

        & a {
          text-decoration: none;
          font-weight: 400;
          font-size: 15px;
          color: var(--white);
        }

        &:hover {
          background-color: var(--ultra-black);

          & a {
            color: var(--theme-primary);
          }
        }
      }
    }
  }
}
