
.d-center {
  justify-content: center;
  align-content: center;
  align-items: center;
}

.d-flex {
  display: flex;
}

.d-flexInline {
  display: inline-flex;
}

.d-flexRow {
  flex-direction: row;
}

.d-flexRowReverse {
  flex-direction: row-reverse;
}

.d-flexCol {
  flex-direction: column;
}

.d-flexColReverse {
  flex-direction: column-reverse;
}

.d-flexWrap {
  flex-wrap: wrap;
}

.d-flexNoWrap {
  flex-wrap: nowrap;
}

.d-flexWrapReverse {
  flex-wrap: wrap-reverse;
}

.d-flexJustifyStart {
  justify-content: flex-start;
}

.d-flexJustifyEnd {
  justify-content: flex-end;
}

.d-flexJustifyCenter {
  justify-content: center;
}

.d-flexJustifyBetween {
  justify-content: space-between;
}

.d-flexJustifyAround {
  justify-content: space-around;
}

.d-flexJustifyEvenly {
  justify-content: space-evenly;
}

.d-flexAlignItemsStart {
  align-items: flex-start;
}

.d-flexAlignItemsEnd {
  align-items: flex-end;
}

.d-flexAlignItemsCenter {
  align-items: center;
}

.d-flexAlignItemsStretch {
  align-items: stretch;
}

.d-flexAlignItemsBaseline {
  align-items: baseline;
}

.d-flexAlignContentStart {
  align-content: flex-start;
}

.d-flexAlignContentEnd {
  align-content: flex-end;
}

.d-flexAlignContentCenter {
  align-content: center;
}

.d-flexAlignContentStretch {
  align-content: stretch;
}

.d-flexAlignContentBetween {
  align-content: space-between;
}

.d-flexAlignContentAround {
  align-content: space-around;
}

.d-flexAlignSelfStart {
  align-self: flex-start;
}

.d-flexAlignSelfEnd {
  align-self: flex-end;
}

.d-flexAlignSelfCenter {
  align-self: center;
}

.d-flexAlignSelfStretch {
  align-self: stretch;
}

.d-flexAlignSelfBaseline {
  align-self: baseline;
}

.d-flexAlignSelfAuto {
  align-self: auto;
}

.d-flexOrderFirst {
  order: -1;
}

.d-flexOrderLast {
  order: 1;
}

.d-flexOrderNone {
  order: 0;
}

.d-flexGrow0 {
  flex: 0 0 0;
}

.d-flexGrow1 {
  flex: 1 1 0%;
  /* 1 */
}

.d-flexGrow2 {
  flex: 2 1 0%;
}

.d-flexGrow3 {
  flex: 3 1 0%;
}

.d-flexGrow4 {
  flex: 4 1 0%;
}

.d-flexGrow5 {
  flex: 5 1 0%;
}

.d-flexShrink0 {
  flex-shrink: 0;
}

.d-flexShrink1 {
  flex-shrink: 1;
}

.d-flexShrink2 {
  flex-shrink: 2;
}

.d-flexShrink3 {
  flex-shrink: 3;
}

.d-flexShrink4 {
  flex-shrink: 4;
}

.d-flexShrink5 {
  flex-shrink: 5;
}

.d-flexExpand {
  margin: auto;
}

.d-flexExpandLeft {
  margin-left: auto;
}

.d-flexExpandRight {
  margin-right: auto;
}

.d-flexExpandTop {
  margin-top: auto;
}

.d-flexExpandBottom {
  margin-bottom: auto;
}

.d-flexBasisAuto {
  flex-basis: auto;
}

.d-flexBasis0 {
  flex-basis: 0;
}

.d-flexInitial {
  flex: 0 1 auto;
  flex-basis: auto;
  /* 1 */
}

.d-flexAuto {
  flex: 1 1 auto;
  flex-basis: auto;
  /* 1 */
}

.d-flexNone {
  flex: 0 0 auto;
  flex-basis: auto;
  /* 1 */
}

.d-flexFlowRowWrap {
  flex-flow: row wrap;
}