.bold-text {
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 22px;
}

.card-title {
  font-weight: 600;
  font-size: 12px;
  color: #464A4F;
}

.card-body {
  font-size: 14px;
  color: #464A4F;
}

.fs-15 > div > button span {
  font-size: 15px;
}