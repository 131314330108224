@import 'app/styles/variables.scss';
@import 'app/styles/typography.scss';
@import 'app/styles/flex_utils.scss';
@import 'app/styles/spacing_utils.scss';
@import 'app/styles/utils.scss';

body {
  background-color: var(--white);
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-width: thin;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%; 
}

.vertical-margin-3 {
  margin-top: 21px;
  margin-bottom: 21px;
}
