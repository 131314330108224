#context-menu-button {
  display: flex;
  width: 100%;
  flex-direction: row;
  padding:12px 10px;
  color: var(--white);
  align-items: center;
  background-color: inherit;
  border: none;
  float: right;
  cursor: pointer;

  .context-menu-icon {
    margin-right: 6px;
    color: var(--white);
  }
}

#context-menu-button:hover {
  background-color: var(--neutral-primary-alt);
}
