.getting-started-checklist {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  background-color: var(--neutral-primary);
  color: var(--white);
  padding: 10px;
  margin: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  width: 300px;
  height: 300px;
}

.getting-started-checklist-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  z-index: 3;
  gap: 10px;
  height: 30px;
  width: 200px;
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 10px;
  border: var(--neutral-primary) 1px solid;
  background-color: var(--neutral-primary);
  color: var(--white);
  padding: 10px;
  margin: 10px;
  user-select: none;

  &:hover {
    cursor: pointer;
    border-color: var(--neutral-primary-alt);
  }
}

.getting-started-checklist-sidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  background-color: var(--white);
  position: absolute;
  bottom: 0;
  left: 240px;
  margin: 0;
  width: 600px;
  height: calc(100% - var(--header-height));
  overflow: auto;
  scrollbar-color: var(--neutral-primary-alt) var(--black);
  scrollbar-width: thin;
  box-shadow: 0 0 10px 0 var(--black);
  z-index: 2;

  &.mobile {
    left: 0;
    width: 100%;
    padding: 0;
  }
}
