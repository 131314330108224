.initial-setup {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  background-color: var(--ultra-black);
  overflow-y: auto;
  scrollbar-color: var(--neutral-primary-alt) var(--black);
  scrollbar-width: thin;
}

.initial-setup__media {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 2rem;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-color: var(--ultra-black);
  color: var(--white);

  transition: transform 0.5s;

  .slide-left {
    -webkit-animation: slide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
  @-webkit-keyframes slide-left {
    0% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
    100% {
      -webkit-transform: translateX(-100px);
      transform: translateX(-100px);
    }
  }
  @keyframes slide-left {
    0% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
    100% {
      -webkit-transform: translateX(-100px);
      transform: translateX(-100px);
    }
  }

  .initial-setup__media__logo {
    width: 300px;
  }

  .initial-setup__media__title {
    text-align: center;
    color: var(--theme-primary);
  }

  .initial-setup__media__step_image {
    width: 400px;
    z-index: 1;
    border-radius: 10px;
  }

  .initial-setup__media__background_logo {
    position: fixed;
    width: 30%;
    bottom: 0;
    right: 0;
  }

  .initial-setup__media__description {
    color: var(--white);
    margin: 2rem;
    font-size: 1.5rem;
    width: 80%;
  }
}

.initial-setup__content__cards {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px 10px 200px 10px;
  gap: 0.5rem;
}

.initial-setup__content {
  background-color: var(--neutral-lighter-alt);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  .initial-setup__content__description {
    margin-left: 2rem;
  }

  .initial-setup__footer {
    background-color: var(--white);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    bottom: 0;
    z-index: 1;
    position: fixed;
    width: 50%;

    .initial-setup__content__buttons {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      margin-left: 20px;
    }
  }
}

.initial-setup-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-color: var(--ultra-black);
}

.initial-setup-mobile__media__logo {
  margin-top: 10px;
  width: 200px;
}

.initial-setup-mobile__media__title {
  text-align: center;
  color: var(--theme-primary);
  margin-bottom: 0;
}

.initial-setup-mobile__media__image {
  width: 15rem;
  border-radius: 10px;
  margin-top: 2rem;
  overflow: auto;
  max-height: 100%;
  display: flex;
  flex-direction: column-reverse;
}

.initial-setup-mobile__media__background_logo {
  position: fixed;
  width: 80%;
  bottom: 0;
  right: 0;
}

.initial-setup-mobile__content__cards {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0 0 0;
  gap: 0.5rem;
  width: 100%;
  z-index: 1;
}

.initial-setup-mobile__footer {
  background-color: var(--white);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  bottom: 0;
  z-index: 1;
  position: fixed;
  width: 100%;

  .initial-setup-mobile__content__buttons {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    margin-left: 20px;
  }
}
