.ens-message {
  position: relative;
  display: flex;
  flex-direction: column;

  color: #ffc900;
  background-color: #402900;

  &--hide {
    display: none;
  }

  &__icon {
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px;

    cursor: pointer;
  }

  &__text {
    padding: 10px 10px;
  }
}
